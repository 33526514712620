import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import NotFound from "@/views/NotFound.vue";

Vue.use(VueRouter);

const metaTitle =
  "Airfield List - best airfields to visit in the UK - where will you fly to next?";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { title: metaTitle },
    component: Home,
  },
  {
    path: "/add",
    name: "Add an Airfield",
    meta: { title: `Add an Airfield - ${metaTitle}` },
    component: () => import(/* webpackChunkName: "add" */ "@/views/Add.vue"),
  },
  {
    path: "/faqs",
    name: "FAQs",
    meta: { title: `FAQs - ${metaTitle}` },
    component: () => import(/* webpackChunkName: "help" */ "@/views/Faqs.vue"),
  },
  {
    path: "/sign-in",
    name: "Sign-In",
    meta: { title: `Sign-In - ${metaTitle}` },
    component: () =>
      import(/* webpackChunkName: "signIn" */ "@/views/SignIn.vue"),
  },
  {
    path: "/my-list",
    name: "My List",
    meta: {
      title: `My List - ${metaTitle}`,
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "myList" */ "@/views/MyList.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: { title: `Page Not Found - ${metaTitle}` },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const currentUser = Vue.prototype.$supabase.auth.currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const goingToSignIn = to.path === "/sign-in";

  if (currentUser && goingToSignIn) {
    // signed in already, visiting sign-in page, so redirect to home page...
    // so no need to show sign-in page again
    next("/");
  } else if (requiresAuth && !currentUser && !goingToSignIn) {
    // page requires auth, no current user, not visiting sign-in page...
    // so redirect to sign-in page
    next("/sign-in");
  } else {
    // proceed as normal
    next();
  }
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
