import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import "./supabase";

Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

store.dispatch("auth/setUser", Vue.prototype.$supabase.auth.user());

Vue.prototype.$supabase.auth.onAuthStateChange((_, session) => {
  store.dispatch("auth/setUser", session ? session.user : null);
});
