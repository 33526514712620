var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[(!_vm.inMyList)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","loading":_vm.isSubmittingMyList},on:{"click":_vm.add}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-bookmark-outline")])],1)]}}],null,false,1682628485)},[(!_vm.userId)?_c('span',[_vm._v("Please sign-in to save airfields to you list")]):_c('span',[_vm._v("Add to my list")])]):_vm._e(),(_vm.inMyList)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","loading":_vm.isSubmittingMyList},on:{"click":_vm.remove}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-bookmark")])],1)]}}],null,false,857617871)},[_c('span',[_vm._v("Remove from my list")])]):_vm._e(),(_vm.inMyList)?_c('span',{staticClass:"display-inline"},[(!_vm.hasVisited)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","loading":_vm.isSubmittingVisited},on:{"click":_vm.setVisited}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-map-marker-check-outline")])],1)]}}],null,false,2212039476)},[_c('span',[_vm._v("Mark airfield as visited")])]):_vm._e(),(_vm.hasVisited)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","loading":_vm.isSubmittingVisited},on:{"click":_vm.unsetVisited}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-map-marker-check")])],1)]}}],null,false,3303423458)},[_c('span',[_vm._v("Remove visited status")])]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }