const auth = {
  namespaced: true,

  strict: process.env.NODE_ENV !== "production",

  state: {
    user: null,
  },

  getters: {
    isSignedIn: (state) => {
      return !!state.user;
    },
    userId: (state) => {
      return state.user?.id;
    },
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },

  actions: {
    async setUser({ commit }, user) {
      commit("setUser", user);
    },
  },
};

export default auth;
