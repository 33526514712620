export const runwayLengthFilters = [
  { text: "100m", value: 100 },
  { text: "200m", value: 200 },
  { text: "300m", value: 300 },
  { text: "400m", value: 400 },
  { text: "500m", value: 500 },
  { text: "600m", value: 600 },
  { text: "700m", value: 700 },
  { text: "800m", value: 800 },
  { text: "900m", value: 900 },
  { text: "1000m", value: 1000 },
];

export const runwaySurfaces = ["Grass", "Asphalt", "Concrete", "Dirt"];

export const winterDrainages = ["Closed", "Soggy", "Damp", "Great"];

export const pprTypes = ["Phone", "Online"];

export const radioServiceTypes = ["A/G", "FIS", "ATC"];

export const airfieldSizes = [
  {
    value: "Farmstrip",
    text: "Farmstrip - farm with an airstrip, or some mown grass",
  },
  {
    value: "Small",
    text: "Small - some facilities, A/G radio service",
  },
  {
    value: "Medium",
    text: "Medium - plenty of facilities, FIS radio",
  },
  {
    value: "Large",
    text: "Large - professional level, executive airport, ATC",
  },
];

export const sceneryRatings = ["Little", "Ok", "Good", "Great", "Stunning"];

export const busynessRatings = ["Sleepy", "Quiet", "Ok", "Busy", "Heaving"];

export const fuelTypes = ["UL91", "100LL", "Jet-A1", "Mogas"];
