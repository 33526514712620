<template>
  <div>
    <h1 class="text-h4">
      Oops
    </h1>
    <p class="body-1">
      We can't find the page you are looking for.
    </p>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
