<template>
  <v-row
    no-gutters
    class="mx-4 mb-4 text-caption"
  >
    <v-col
      cols="8"
      sm="6"
      md="4"
    >
      Airfield
    </v-col>
    <v-col
      cols="3"
      md="2"
      class="text-right text-sm-left"
    >
      Size / Runways
    </v-col>
    <v-col
      sm="1"
      class="d-none d-sm-flex"
    >
      Cost
    </v-col>
    <v-col
      sm="1"
      class="d-none d-sm-flex"
    >
      PPR?
    </v-col>
    <v-col
      sm="1"
      class="d-none d-sm-flex"
    >
      Radio
    </v-col>
    <v-col
      cols="12"
      md="3"
      class="d-none d-md-block text-right"
    >
      Facilities
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AirfieldListHeader",
};
</script>
