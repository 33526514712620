import Vue from "vue";

const airfields = {
  namespaced: true,

  strict: process.env.NODE_ENV !== "production",

  state: {
    items: [],
    filters: {
      size: null,
      runwaySurface: null,
      runwayLength: null,
      fuelType: null,
      accommodation: null,
      foodDrink: null,
      instrumentApproaches: null,
      toilets: null,
    },
    myItems: [],
    myFilters: {
      size: null,
      runwaySurface: null,
      runwayLength: null,
      fuelType: null,
      accommodation: null,
      foodDrink: null,
      instrumentApproaches: null,
      toilets: null,
      visited: null,
    },
  },

  getters: {
    items: (state) => {
      return state.items || [];
    },
    myItems: (state) => {
      return state.myItems || [];
    },
  },

  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    applyFilters(state, filters) {
      state.filters = filters;
    },

    setMyItems(state, items) {
      state.myItems = items;
    },
    applyMyFilters(state, filters) {
      state.myFilters = filters;
    },

    addedToMyList(state, airfieldId, userId, visited) {
      const match = (airfield) => airfield.id === airfieldId;

      const item = state.items.find(match);
      if (item) {
        item.userAirfields = [
          {
            user_id: userId,
            airfield_id: airfieldId,
            visited: visited,
          },
        ];
      }
    },
    removedFromMyList(state, airfieldId) {
      const match = (airfield) => airfield.id === airfieldId;

      const item = state.items.find(match);
      if (item) item.userAirfields = [];

      const myItemsIndex = state.myItems.findIndex(match);
      if (myItemsIndex >= 0) state.myItems.splice(myItemsIndex, 1);
    },
    updatedInMyList(state, data) {
      const match = (airfield) => airfield.id === data.airfieldId;

      const item = state.items.find(match);
      if (item) item.userAirfields[0].visited = data.visited;

      const myItem = state.myItems.find(match);
      if (myItem) myItem.userAirfields[0].visited = data.visited;
    },
  },

  actions: {
    async fetch({ commit, state }) {
      var qb = Vue.prototype.$supabase
        .from("airfields")
        .select("*, userAirfields(user_id, airfield_id, visited)")
        .order("name", { ascending: true });

      qb = filtersToQuery(state.filters, qb);

      const { data, error } = await qb;

      if (error) {
        console.error(error);
      } else {
        commit("setItems", data);
      }
    },

    async fetchMyList({ commit, state }) {
      var qb = Vue.prototype.$supabase
        .from("airfields")
        .select("*, userAirfields!inner(user_id, airfield_id, visited)");

      qb = filtersToQuery(state.myFilters, qb);

      if (state.myFilters.visited == "visited") {
        qb = qb.eq("userAirfields.visited", true);
      } else if (state.myFilters.visited == "not-visited") {
        qb = qb.eq("userAirfields.visited", false);
      }

      const { data, error } = await qb;

      if (error) {
        console.error(error);
      } else {
        commit("setMyItems", data);
      }
    },

    async applyFilters({ commit, dispatch }, filters) {
      commit("applyFilters", {
        size: filters.size,
        runwaySurface: filters.runwaySurface,
        runwayLength: filters.runwayLength,
        accommodation: filters.accommodation,
        foodDrink: filters.foodDrink,
        fuelType: filters.fuelType,
        instrumentApproaches: filters.instrumentApproaches,
        toilets: filters.toilets,
        visited: filters.visited,
      });
      await dispatch("fetch");
    },

    async applyMyFilters({ commit, dispatch }, filters) {
      commit("applyMyFilters", {
        size: filters.size,
        runwaySurface: filters.runwaySurface,
        runwayLength: filters.runwayLength,
        accommodation: filters.accommodation,
        foodDrink: filters.foodDrink,
        fuelType: filters.fuelType,
        instrumentApproaches: filters.instrumentApproaches,
        toilets: filters.toilets,
        visited: filters.visited,
      });
      await dispatch("fetchMyList");
    },

    async add(_, airfield) {
      const data = {
        country: "GB", // TODO: REMOVE THIS when country is a field on the form
        ...airfield,
        published: false,
      };

      const { error } = await Vue.prototype.$supabase
        .from("airfields")
        .insert([data], { returning: "minimal" });

      if (error) {
        console.error(error);
        return false;
      }

      return true;
    },

    async addToMyList({ commit }, airfieldId) {
      const userId = Vue.prototype.$supabase.auth.currentUser?.id;
      if (!userId) throw new Error("Cannot add to list if not signed in!");

      const data = {
        user_id: userId,
        airfield_id: airfieldId,
        visited: false,
      };

      const { error } = await Vue.prototype.$supabase
        .from("userAirfields")
        .insert([data], { returning: "minimal" });

      if (error) {
        console.error(error);
        return false;
      }

      commit("addedToMyList", airfieldId);

      return true;
    },
    async removeFromMyList({ commit }, airfieldId) {
      const userId = Vue.prototype.$supabase.auth.currentUser?.id;
      if (!userId) throw new Error("Cannot remove from list if not signed in!");

      const { error } = await Vue.prototype.$supabase
        .from("userAirfields")
        .delete({ returning: "minimal" })
        .match({
          user_id: userId,
          airfield_id: airfieldId,
        });

      if (error) {
        console.error(error);
        return false;
      }

      commit("removedFromMyList", airfieldId);

      return true;
    },

    async setVisited({ commit }, airfieldId) {
      const userId = Vue.prototype.$supabase.auth.currentUser?.id;
      if (!userId)
        throw new Error("Cannot set visited status if not signed in!");

      const { error } = await Vue.prototype.$supabase
        .from("userAirfields")
        .update({ visited: true })
        .match({
          user_id: userId,
          airfield_id: airfieldId,
        });

      if (error) {
        console.error(error);
        return false;
      }

      commit("updatedInMyList", { airfieldId, visited: true });

      return true;
    },
    async unsetVisited({ commit }, airfieldId) {
      const userId = Vue.prototype.$supabase.auth.currentUser?.id;
      if (!userId)
        throw new Error("Cannot unset visited status if not signed in!");

      const { error } = await Vue.prototype.$supabase
        .from("userAirfields")
        .update({ visited: false })
        .match({
          user_id: userId,
          airfield_id: airfieldId,
        });

      if (error) {
        console.error(error);
        return false;
      }

      commit("updatedInMyList", { airfieldId, visited: false });

      return true;
    },
  },
};

function filtersToQuery(filters, qb) {
  if (filters.size) {
    qb = qb.eq("size", filters.size);
  }
  if (filters.runwaySurface) {
    qb = qb.contains("runwaySurfaces", [filters.runwaySurface]);
  }
  if (filters.runwayLength) {
    qb = qb.gte("runwayMaxLength", filters.runwayLength);
  }
  if (filters.foodDrink) {
    qb = qb.eq("foodDrink", true);
  }
  if (filters.toilets) {
    qb = qb.eq("toilets", true);
  }
  if (filters.accommodation) {
    qb = qb.eq("accommodation", true);
  }
  if (filters.instrumentApproaches) {
    qb = qb.eq("instrumentApproaches", true);
  }
  if (filters.fuelType) {
    qb = qb.contains("fuelTypes", [filters.fuelType]);
  }

  return qb;
}

export default airfields;
