<template>
  <div class="mt-0 mb-2 py-2">
    <v-row justify="space-between">
      <v-col cols="auto">
        <v-btn
          v-if="!showFilters"
          rounded
          color="primary"
          @click="showFilters = true"
          title="Show all filters"
        >
          Filters
          <v-icon right>
            mdi-menu-down
          </v-icon>
        </v-btn>
        <v-btn
          v-if="showFilters"
          outlined
          rounded
          color="primary"
          @click="showFilters = false"
          title="Hide all filters"
        >
          Filters
          <v-icon right>
            mdi-menu-up
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <slot />
      </v-col>
    </v-row>
    <v-expand-transition>
      <div
        v-if="!showFilters"
        class="my-2"
      >
        <v-chip-group>
          <v-chip
            v-if="filters.size"
            close
            @click:close="
              filters.size = null;
              apply();
            "
          >
            size: {{ filters.size }}
          </v-chip>
          <v-chip
            v-if="filters.runwaySurface"
            close
            @click:close="
              filters.runwaySurface = null;
              apply();
            "
          >
            surface: {{ filters.runwaySurface }}
          </v-chip>
          <v-chip
            v-if="filters.runwayLength"
            close
            @click:close="
              filters.runwayLength = null;
              apply();
            "
          >
            length: {{ filters.runwayLength }}m
          </v-chip>
          <v-chip
            v-if="filters.fuelType"
            close
            @click:close="
              filters.fuelType = null;
              apply();
            "
          >
            fuel: {{ filters.fuelType }}
          </v-chip>
          <v-chip
            v-if="filters.foodDrink"
            close
            @click:close="
              filters.foodDrink = null;
              apply();
            "
          >
            food/drink
          </v-chip>
          <v-chip
            v-if="filters.toilets"
            close
            @click:close="
              filters.toilets = null;
              apply();
            "
          >
            toilets
          </v-chip>
          <v-chip
            v-if="filters.accommodation"
            close
            @click:close="
              filters.accommodation = null;
              apply();
            "
          >
            accommodation
          </v-chip>
          <v-chip
            v-if="filters.visited == 'not-visited'"
            close
            @click:close="
              filters.visited = null;
              apply();
            "
          >
            not visited
          </v-chip>
          <v-chip
            v-if="filters.visited == 'visited'"
            close
            @click:close="
              filters.visited = null;
              apply();
            "
          >
            visited
          </v-chip>
        </v-chip-group>
      </div>
      <v-row
        v-if="showFilters"
        class="filters my-2"
        justify="start"
      >
        <v-col
          cols="auto"
          class="py-2"
        >
          <v-select
            :items="airfieldSizes"
            item-text="value"
            v-model="filters.size"
            prepend-inner-icon="mdi-ruler"
            clearable
            label="Airfield size"
            dense
            outlined
            rounded
            hide-details
            class="select-airfield-size"
          />
        </v-col>
        <v-col
          cols="auto"
          class="py-2"
        >
          <v-select
            :items="runwaySurfaces"
            v-model="filters.runwaySurface"
            prepend-inner-icon="mdi-grass"
            clearable
            label="Runway surface"
            dense
            outlined
            rounded
            hide-details
            class="select-runway-surface"
          />
        </v-col>
        <v-col
          cols="auto"
          class="py-2"
        >
          <v-select
            :items="runwayLengthFilters"
            v-model="filters.runwayLength"
            prepend-inner-icon="mdi-road-variant"
            clearable
            label="Runway length"
            dense
            outlined
            rounded
            hide-details
            class="select-runway-length"
          />
        </v-col>
        <v-col
          cols="auto"
          class="py-2"
        >
          <v-select
            :items="fuelTypes"
            v-model="filters.fuelType"
            prepend-inner-icon="mdi-gas-station"
            clearable
            label="Fuel type"
            dense
            outlined
            rounded
            hide-details
            class="select-fuel-type"
          />
        </v-col>
        <v-col
          cols="auto"
          class="py-2"
        >
          <v-switch
            dense
            v-model="filters.foodDrink"
            prepend-icon="mdi-food"
            label="Food &amp; Drink available?"
            title="Food &amp; Drink available?"
            hide-details
          />
        </v-col>
        <v-col
          cols="auto"
          class="py-2"
        >
          <v-switch
            dense
            v-model="filters.toilets"
            prepend-icon="mdi-human-male-female"
            label="Toilets available?"
            title="Toilets available?"
            hide-details
          />
        </v-col>
        <v-col
          cols="auto"
          class="py-2"
        >
          <v-switch
            dense
            v-model="filters.accommodation"
            prepend-icon="mdi-bed"
            label="Accommodation available?"
            title="Accommodation available?"
            hide-details
          />
        </v-col>
        <v-col
          v-if="showVisited"
          cols="auto"
          class="py-2"
        >
          <v-btn-toggle
            v-model="filters.visited"
            dense
            rounded
          >
            <v-btn value="not-visited">
              <v-icon>mdi-map-marker-check-outline</v-icon>
              New
            </v-btn>
            <v-btn value="visited">
              <v-icon>mdi-map-marker-check</v-icon>
              Visited
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col
          cols="auto"
          class="py-2"
        >
          <v-btn
            rounded
            color="primary"
            @click="apply"
            title="Apply filter"
            :loading="applyingFilters"
          >
            Apply
          </v-btn>
        </v-col>
      </v-row>
    </v-expand-transition>
  </div>
</template>

<script>
import {
  airfieldSizes,
  runwaySurfaces,
  runwayLengthFilters,
  fuelTypes,
} from "@/data/constants";

export default {
  name: "AirfieldFilters",
  props: {
    applyFilters: { type: Function, required: true },
    showVisited: { type: Boolean, default: false },
  },

  data() {
    return {
      showFilters: false,
      applyingFilters: false,
      filters: {
        size: null,
        runwaySurface: null,
        runwayLength: null,
        accommodation: null,
        foodDrink: null,
        fuelType: null,
        instrumentApproaches: null,
        toilets: null,
        visited: null,
      },
      airfieldSizes,
      runwaySurfaces,
      runwayLengthFilters,
      fuelTypes,
    };
  },

  methods: {
    async apply() {
      this.applyingFilters = true;
      await this.applyFilters(this.filters);
      this.showFilters = false;
      this.applyingFilters = false;
    },
  },
};
</script>

<style lang="scss">
.filters {
  .v-text-field--rounded > .v-input__control > .v-input__slot {
    padding: 0 12px 0 14px;
  }
  .v-text-field--outlined.v-text-field--rounded legend {
    margin-left: 2px !important;
  }
  .v-input {
    margin-top: -1px;
  }
  .v-input--selection-controls {
    margin-top: 3px;
  }
  .select-airfield-size {
    max-width: 12rem;
  }
  .select-runway-length {
    max-width: 12rem;
  }
  .select-runway-surface {
    max-width: 12rem;
  }
  .select-fuel-type {
    max-width: 11rem;
  }

  // checkbox styling
  .v-input--selection-controls__input {
    margin-right: 0 !important;
  }
  .v-input--selection-controls .v-input__slot > .v-label,
  .v-input--selection-controls .v-radio > .v-label {
    display: none;
  }
}
</style>
