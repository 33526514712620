<template>
  <v-card class="mt-4">
    <v-card-text>
      <v-row no-gutters>
        <v-col
          cols="8"
          sm="6"
          md="4"
          align-self="start"
        >
          <h3 class="text-h5">
            {{ data.name }}
          </h3>
          <div class="text-body-2">
            <span v-if="data.icaoCode">
              {{ data.icaoCode }}
              &nbsp;&bullet;&nbsp;
            </span>
            {{ data.address }}
          </div>
          <div class="mt-1 no-wrap">
            <a
              v-if="data.websiteUrl"
              :href="data.websiteUrl"
              :title="'Open ' + data.websiteUrl + ' in a new tab'"
              target="_blank"
              rel="noopener"
            >
              {{ data.websiteUrl }}
            </a>
          </div>
          <airfield-option-buttons
            v-if="showOptionButtons"
            :airfield-id="data.id"
            :in-my-list="data.userAirfields && data.userAirfields.length > 0"
            :has-visited="
              data.userAirfields &&
                data.userAirfields.length > 0 &&
                data.userAirfields[0].visited
            "
          />
        </v-col>

        <v-col
          cols="4"
          md="2"
          class="text-right text-sm-left"
          align-self="center"
        >
          <div>{{ data.size }}</div>
          <div class="mt-2">
            {{ data.runwaySurfaces | join(", ") }}<br>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                  Max: {{ data.runwayMaxLength }}&nbsp;m
                </span>
              </template>
              <span>Maximum runway length available</span>
            </v-tooltip>
          </div>
        </v-col>

        <v-col
          sm="1"
          class="d-none d-sm-flex"
          align-self="center"
        >
          <div
            v-if="data.cost !== null"
            class="no-wrap"
          >
            <v-icon
              v-for="n in data.cost"
              :key="n"
              small
              style="margin-right: -5px"
            >
              mdi-currency-gbp
            </v-icon>
          </div>
        </v-col>

        <v-col
          sm="1"
          class="d-none d-sm-flex"
          align-self="center"
        >
          <div v-if="data.ppr">
            <v-icon
              v-if="data.pprTypes && data.pprTypes.includes('Online')"
              title="PPR online"
              class="my-1"
            >
              mdi-laptop
            </v-icon>
            <v-icon
              v-if="data.pprTypes && data.pprTypes.includes('Phone')"
              title="PPR by phone"
              class="my-1"
            >
              mdi-phone
            </v-icon>
          </div>
        </v-col>

        <v-col
          sm="1"
          class="d-none d-sm-flex"
          align-self="center"
        >
          <span v-if="data.radioService">
            {{ data.radioServiceType }}
          </span>
        </v-col>

        <v-col
          cols="12"
          md="3"
          class="text-center text-md-right mt-3 mt-md-0"
          align-self="center"
        >
          <v-chip
            v-if="data.foodDrink"
            :title="data.foodDrinkText"
            small
            color="secondary"
            class="ma-1"
          >
            <v-icon
              left
              small
            >
              mdi-food
            </v-icon>
            Eat
          </v-chip>
          <v-chip
            v-if="data.toilets"
            small
            color="secondary"
            class="ma-1"
          >
            <v-icon
              left
              small
            >
              mdi-human-male-female
            </v-icon>
            Toilets
          </v-chip>
          <v-chip
            v-if="data.clubHouse"
            small
            color="secondary"
            class="ma-1"
          >
            <v-icon
              left
              small
            >
              mdi-home
            </v-icon>
            Clubhouse
          </v-chip>
          <v-chip
            v-if="data.accommodation"
            :title="data.accommodationText"
            small
            color="secondary"
            class="ma-1"
          >
            <v-icon
              left
              small
            >
              mdi-bed
            </v-icon>
            Sleep
          </v-chip>
          <v-chip
            v-if="data.transportation"
            :title="data.transportationText"
            small
            color="secondary"
            class="ma-1"
          >
            <v-icon
              left
              small
            >
              mdi-hail
            </v-icon>
            Transport
          </v-chip>
          <v-chip
            v-if="data.fuel"
            :title="data.fuelTypes | join(', ')"
            small
            color="secondary"
            class="ma-1"
          >
            <v-icon
              left
              small
            >
              mdi-gas-station
            </v-icon>
            Fuel
          </v-chip>
          <v-chip
            v-if="data.maintenance"
            :title="data.maintenanceText"
            small
            color="secondary"
            class="ma-1"
          >
            <v-icon
              left
              small
            >
              mdi-wrench
            </v-icon>
            Maintenance
          </v-chip>
          <v-chip
            v-if="data.flightSchool"
            :title="data.flightSchoolText"
            small
            color="secondary"
            class="ma-1"
          >
            <v-icon
              left
              small
            >
              mdi-school
            </v-icon>
            School
          </v-chip>
          <v-chip
            v-if="data.instrumentApproaches"
            title="Instrument are approaches available"
            small
            color="secondary"
            class="ma-1"
          >
            <v-icon
              left
              small
            >
              mdi-navigation-variant
            </v-icon>
            IR Appr.
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AirfieldOptionButtons from "./AirfieldOptionButtons.vue";

export default {
  name: "AirfieldCard",
  props: {
    data: { type: Object, required: true },
    showOptionButtons: { type: Boolean, default: true },
  },

  components: { AirfieldOptionButtons },

  filters: {
    join(value, glue) {
      if (!value) return "";
      if (typeof value !== "object") return "";
      return value.join(glue);
    },
  },
};
</script>
