<template>
  <v-btn
    id="darkMode"
    title="Toggle dark mode"
    icon
    small
    @click="toggleDarkMode"
  >
    <v-icon
      v-if="theme === 'dark'"
      title="Switch to Light mode"
    >
      mdi-white-balance-sunny
    </v-icon>
    <v-icon
      v-else-if="theme === 'light'"
      title="Switch to Dark mode"
    >
      mdi-weather-night
    </v-icon>
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ThemeToggle",

  computed: {
    ...mapGetters({
      theme: "getTheme",
    }),
  },

  methods: {
    toggleDarkMode() {
      this.$store.dispatch("toggleTheme");
    },
  },
};
</script>
