<template>
  <v-card
    color="accent"
    class="mt-4"
    :href="data.url"
    target="_blank"
    rel="noopener"
  >
    <v-card-text>
      <v-row no-gutters>
        <v-col class="text-center">
          <p class="text-subtitle-2 mb-1">
            {{ data.title }}
          </p>
          <p class="text-caption mb-0">
            {{ data.tagline }}
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SponsorCard",
  props: {
    data: { type: Object, required: true },
  },
};
</script>
