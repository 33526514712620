<template>
  <v-app>
    <v-main>
      <header class="top-bar pt-md-2 pb-md-4">
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="4"
              class="text-right text-md-left pb-0 pb-md-2"
            >
              <theme-toggle />
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="text-center py-0 py-md-2"
            >
              <h1 class="brand text-h4">
                <router-link to="/">
                  <v-icon
                    x-large
                    color="primary"
                  >
                    mdi-road-variant
                  </v-icon>
                  <br>
                  Airfield List
                </router-link>
              </h1>
              <h2 class="text-h6 grey--text text--darken-1">
                where will you go next?
              </h2>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="text-center text-md-right ps-0 pe-0"
              align-self="center"
            >
              <v-btn
                text
                rounded
                to="/faqs"
                title="Frequently asked questions about Airfield List"
                class="mx-1"
              >
                <v-icon
                  small
                  left
                >
                  mdi-help-circle-outline
                </v-icon>
                FAQs
              </v-btn>
              <v-btn
                v-if="isSignedIn"
                text
                rounded
                to="/my-list"
                title="My airfield list"
                class="mx-1"
              >
                <v-icon
                  small
                  left
                >
                  mdi-bookmark-multiple
                </v-icon>
                My List
              </v-btn>
              <v-btn
                v-if="isSignedIn"
                text
                rounded
                @click="signOut"
                title="Sign-Out"
                class="mx-1"
                :loading="signingOut"
              >
                <v-icon
                  small
                  left
                >
                  mdi-logout
                </v-icon>
                Sign-Out
              </v-btn>
              <v-btn
                v-if="!isSignedIn"
                text
                rounded
                to="/sign-in"
                title="Sign-In"
                class="mx-1"
              >
                <v-icon
                  small
                  left
                >
                  mdi-login
                </v-icon>
                Sign-In
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </header>
      <v-divider />
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <v-footer
      color="transparent"
      class="pa-4"
    >
      <v-row>
        <v-col class="text-caption text-center">
          &copy;&nbsp;2021 airfieldlist.com
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import ThemeToggle from "./components/ThemeToggle.vue";

export default {
  name: "App",

  components: { ThemeToggle },

  beforeMount() {
    this.$store.dispatch("initTheme");
  },

  data() {
    return {
      signingOut: false,
    };
  },

  computed: {
    ...mapGetters({
      isSignedIn: "auth/isSignedIn",
    }),
  },

  methods: {
    async signOut() {
      try {
        this.signingOut = true;
        let { error } = await this.$supabase.auth.signOut();
        if (error) throw error;
      } catch (error) {
        alert(error.message);
      } finally {
        this.signingOut = false;
      }
    },
  },
};
</script>

<style lang="scss">
header.top-bar {
  .brand {
    a {
      text-decoration: none;
    }
  }
}
.theme--light header.top-bar {
  background-color: white;
}
.theme--dark header.top-bar {
  background-color: #303030;
}
</style>
