import Vue from "vue";
import VueSupabase from "vue-supabase";

const SUPABASE_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYzNzE4Njk5NSwiZXhwIjoxOTUyNzYyOTk1fQ.Cz9BFsVKj-5qQ1fC-D2-C54-kGpqzRyDs4IrRS09BAg";

Vue.use(VueSupabase, {
  supabaseUrl: "https://gphubjorxabfselcsjlh.supabase.co",
  supabaseKey: SUPABASE_KEY,
  supabaseOptions: {},
});
