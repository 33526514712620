import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations } from "vuexfire";
import airfields from "./airfields";
import auth from "./auth";
import theme from "./theme";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",

  state: {},
  mutations: {
    ...vuexfireMutations,
  },
  actions: {},
  modules: {
    theme,
    auth,
    airfields,
  },
});
