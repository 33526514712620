<template>
  <div class="mt-2">
    <v-tooltip
      v-if="!inMyList"
      top
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          small
          @click="add"
          :loading="isSubmittingMyList"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon> mdi-bookmark-outline</v-icon>
        </v-btn>
      </template>
      <span v-if="!userId">Please sign-in to save airfields to you list</span>
      <span v-else>Add to my list</span>
    </v-tooltip>
    <v-tooltip
      v-if="inMyList"
      top
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          small
          @click="remove"
          :loading="isSubmittingMyList"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon> mdi-bookmark</v-icon>
        </v-btn>
      </template>
      <span>Remove from my list</span>
    </v-tooltip>

    <span
      v-if="inMyList"
      class="display-inline"
    >
      <v-tooltip
        v-if="!hasVisited"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            small
            @click="setVisited"
            :loading="isSubmittingVisited"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon> mdi-map-marker-check-outline</v-icon>
          </v-btn>
        </template>
        <span>Mark airfield as visited</span>
      </v-tooltip>
      <v-tooltip
        v-if="hasVisited"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            small
            @click="unsetVisited"
            :loading="isSubmittingVisited"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon> mdi-map-marker-check</v-icon>
          </v-btn>
        </template>
        <span>Remove visited status</span>
      </v-tooltip>
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AirfieldOptionButtons",
  props: {
    inMyList: { type: Boolean, required: true, default: false },
    hasVisited: { type: Boolean, required: true, default: false },
    airfieldId: { type: String, required: true },
  },

  data() {
    return {
      isSubmittingMyList: false,
      isSubmittingVisited: false,
    };
  },

  computed: {
    ...mapGetters({
      userId: "auth/userId",
    }),
  },

  methods: {
    async add() {
      if (!this.userId) {
        await this.$router.push("/sign-in");
        return;
      }

      this.isSubmittingMyList = true;
      await this.$store.dispatch("airfields/addToMyList", this.airfieldId);
      this.isSubmittingMyList = false;
    },
    async remove() {
      if (!this.userId) return;

      this.isSubmittingMyList = true;
      await this.$store.dispatch("airfields/removeFromMyList", this.airfieldId);
      this.isSubmittingMyList = false;
    },

    async setVisited() {
      if (!this.userId) return;

      this.isSubmittingVisited = true;
      await this.$store.dispatch("airfields/setVisited", this.airfieldId);
      this.isSubmittingVisited = false;
    },
    async unsetVisited() {
      if (!this.userId) return;

      this.isSubmittingVisited = true;
      await this.$store.dispatch("airfields/unsetVisited", this.airfieldId);
      this.isSubmittingVisited = false;
    },
  },
};
</script>
