import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.deepOrange.darken3,
        secondary: colors.deepPurple.base,
        accent: colors.blue.lighten3,
      },
      dark: {
        primary: colors.deepOrange.darken4,
        secondary: colors.deepPurple.darken2,
        accent: colors.blueGrey.darken3,
      },
    },
  },
});
